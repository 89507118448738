.tnt-video-container {
    position:initial;
    overflow:hidden;
    height: auto;
    width: 100%;
}

.tnt-video-container.video-responsive{
    padding-top:0;
}

.tnt-video-container video{
    width:100%;
}

.tnt-video.video-js .vjs-big-play-button{
    background:none;
    box-shadow:none;
    opacity:.8;
    transition:opacity .25s ease-in-out;
    margin-top:-40px;
    width:75px;
    height:75px;
    border-radius:50%;
    border-style:solid;
    border-width:4px;
    border-color:#FFFFFF;
}

.tnt-video.video-js:hover .vjs-big-play-button{
    opacity:1;
}

.tnt-video.video-js .vjs-big-play-button:before{
    font-size:64px;
    margin-top:17%;
}

.tnt-video.video-js.tnt-spinner .vjs-big-play-button,
.tnt-video.video-js.tnt-spinner:hover .vjs-big-play-button,
.tnt-video.video-js.vjs-playing .vjs-big-play-button,
.tnt-video.video-js.vjs-playing:hover .vjs-big-play-button,
.tnt-video.video-js.vjs-ad-playing .vjs-big-play-button,
.tnt-video.video-js.vjs-ad-playing:hover .vjs-big-play-button{
    display:none;
    opacity:0;
}

.tnt-video.video-js.vjs-7 .vjs-big-play-button .vjs-icon-placeholder:before{
    font-size:75px;
    position:absolute;
    top:10px;
    left:-1px;
}

.tnt-video.video-js.tnt-ad-spinner .vjs-loading-spinner{
    display:block;
}


.tnt-video.video-js.vjs-ad-playing .vjs-control-bar,
.tnt-video-ad.vjs-default-skin.vjs-has-started .vjs-control-bar{
    display:none;
}

.tnt-video-endcard{
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    background: rgba(0,0,0,.9);
    z-index:100;
}

.tnt-video-endcard .engagment-mask{
    width:100%;
    height:100%;
    background:#000000;
}

.tnt-video-endcard .engagement-container{
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
}

.tnt-video-endcard .card-panel,
.tnt-video-endcard .endcard-assets article{
    background:#111111;
    border:solid 1px rgba(0,0,0,.08);
    margin-bottom:0px;
}

.tnt-video-endcard .endcard-replay{
    cursor:pointer;
}

.tnt-video-endcard .engagement-container .engagement-item-static .card-headline h4{
    font-size:50px;
    position:absolute;
    top:5px;
    left:10px;
}

.tnt-video-endcard .engagement-container .engagement-item-static .replay-label{
    position:absolute;
    bottom:0;
    left:0;
    padding:4px;
    color:#fff;
    background-color:rgba(0,0,0,0.65);
    z-index:2;
    margin:0;
    font-size:10px;
}
.tnt-video-endcard .engagement-item-static .replay-label:hover{
    display:none;
}

.tnt-video-container .tnt-audio .vjs-poster{ 
    display: block!important; 
}

.tn-mediaplayer-widget .tnt-video-container .tnt-audio,
.tn-mediaplayer-widget .tnt-video-container .tnt-audio .vjs-poster {
    background-color: transparent;
}

/* hls live stream styles */
.tnt-video-container[data-provider=m3u8][data-live=true] .vjs-control-bar .vjs-play-control.vjs-playing,
.tnt-video-container[data-provider=m3u8][data-live=true] .vjs-control-bar .vjs-live-control .vjs-live-display,
.tnt-video-container[data-provider=field59] .vjs-control-bar .vjs-live-control .vjs-live-display,
.tnt-video-container[data-provider=field59] .vjs-control-bar .vjs-seek-to-live-control {
    display:none;
}

/* closed caption settings styles */
.vjs-text-track-settings .vjs-track-settings-colors,
.vjs-text-track-settings .vjs-track-settings-font{ color:#000000; }

/* new video player widgets */
.tn-mediaplayer-container {
    position:relative;
    overflow:hidden;
    background:#111111;
    color:#ffffff;
}

.tn-mediaplayer-loading {
    display: none;
}

.tn-mediaplayer-loading.active {
    display: block;
}

.tn-mediaplayer-container .tn-mediaplayer-loading {
    background: #000;
    font-size: 24px;
    z-index: 99;
    position:absolute;
    top:0;
    bottom: 0;
    left:0;
    width:100%;
}

.tn-mediaplayer-loading{
    padding-top:56.25%;
}
.tn-mediaplayer-loading .tnt-spinner{
    position:absolute;
    top:50%;
    width:100%;
    text-align:center;
}

.tn-mediaplayer-widget {
        display: grid;
        grid-template-columns: repeat(1,  minmax(0, 1fr));
        grid-template-rows: auto;
        grid-row-gap: 10px;
        grid-template-areas:
            "player" 
            "list"
            "text"
            "ad";
}

.tn-mediaplayer-widget.tn-mediaplayer-no-companion {
    grid-template-areas:
            "player" 
            "list"
            "text";
    padding-bottom: 0px;
}

@media screen and (min-width: 768px) {
    .tn-mediaplayer-widget {
        grid-template-columns: 1fr 340px;
        grid-row-gap: 20px;
        grid-template-areas:
            "player player" 
            "list list"
            "text ad";
    }
    
    .tn-mediaplayer-widget.tn-mediaplayer-no-companion {
        grid-template-columns: repeat(1,  minmax(0, 1fr));
    }
    
    .tn-mediaplayer-widget .tn-media-ad {
        margin-right: 10px;
    }
}

.tn-mediaplayer-widget .tn-mediaplayer-container {
    grid-area: player;
}

.tn-mediaplayer .enhanced-playlist-slideshow {
    grid-area: list;
}

.tn-mediaplayer-text {
    grid-area: text;
}

.tn-mediaplayer-widget .tn-media-ad {
    grid-area: ad;
}

.tn-mediaplayer-widget .tn-mediaplayer-area {
    display: grid;
    place-items: center center;
}

.tn-mediaplayer-widget .tn-mediaplayer-area .photo,
.tn-mediaplayer-widget .tn-mediaplayer-area figure.photo div,
.tn-mediaplayer-widget .tn-mediaplayer-area .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.tn-mediaplayer-widget .tn-mediaplayer-area .photo img {
    width: auto;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.tn-mediaplayer-widget .tn-mediaplayer-area .photo .tnt-photo-purchase {
    position: absolute;
    z-index: 9;
    right: 0px;
    bottom: 0px;
}

.tn-mediaplayer-widget .card-headline {
    display: none;
}

@media screen and (min-width: 1280px) {
    .enhanced-video .tn-mediaplayer-widget .tn-media-playlist-up-next {
        padding: 0 20px 20px 0;
    }
}

.tn-mediaplayer-widget .tn-mediaplayer-description {
    min-height: 65px;
    overflow: hidden;
}

.tn-mediaplayer-widget .tn-mediaplayer-description.collapsed {
    height: 65px;
}

.tn-mediaplayer-widget .tn-mediaplayer-description.collapsed.truncate { 
    min-height: 45px;
    height: 45px;
}

.tn-mediaplayer-widget .tn-mediaplayer-description.collapsed.truncate .card {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.tn-mediaplayer-widget .tn-mediaplayer-description.collapsed .tnt-headline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tn-mediaplayer-widget .tn-mediaplayer-description .card-lead p {
    margin-bottom: 0px;
}

.tn-mediaplayer-widget .tn-mediaplayer-description .card {
    margin-bottom: 0px;
}

/* Center ad slide */
.tn-mediaplayer-widget .media-ad {
    display: grid;
    place-items: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.tn-mediaplayer-widget .tnt-ads, 
.tn-mediaplayer-widget .tnt-ads-container>div:first-child {
    margin-bottom: 0px;
}

.tn-mediaplayer-area a.video-overlay-play-button {
    color: rgba(255,255,255,1) !important;
    filter: drop-shadow(2px 0px 4px rgba(0,0,0,1));
    opacity: 0.6;
    transition: opacity 1s;
    z-index: 9;
}

.tn-mediaplayer-area a.video-overlay-play-button:hover {
    opacity: 0.8;
}

.tn-mediaplayer-widget .tn-mediaplayer-container .tn-mediaplayer-area .card {
    margin-bottom: 0px;
}

.tn-mediaplayer-area .tnt-video.video-js .vjs-big-play-button,
.tn-mediaplayer-widget .tn-mediaplayer-container .tn-mediaplayer-area .card-body,
.tn-mediaplayer-widget .tn-mediaplayer-container .tn-mediaplayer-area .media-preview .asset-icon,
.tn-mediaplayer-widget .tn-mediaplayer-container .playlist-content .card-body,
.tn-mediaplayer-widget .tn-media-playlist-up-next .playlist-content .card-body {
    display: none;
}

.tn-media-playlist .tn-playlist-item {
    position: relative;
    margin: 0;
}

.tn-media-playlist .tn-playlist-item .playlist-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "image image image"
        "body body body";
}
.tn-media-playlist[data-grid-cols=1] .tn-playlist-item .playlist-content {
    grid-template-areas: "image body body";
}
.tn-media-playlist .tn-playlist-item.current .playlist-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.tn-mediaplayer-widget .player-container,
.tn-media-playlist .tn-playlist-item {
    border: solid 1px rgba(0,0,0,0.1);
    box-shadow: 2px 2px rgba(0,0,0,0.1);
}
.tn-media-playlist .tn-playlist-item .playlist-content .card-image {
    grid-area: image;
}
.tn-media-playlist .tn-playlist-item .playlist-content .card-body {
    grid-area: body;
}
.tn-media-playlist .tn-playlist-item .tnt-download a {
    border: none;
    background: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
}
.tn-media-playlist .tn-playlist-item .playlist-content,
.tn-mediaplayer-widget .tn-media-playlist-up-next {
    position: relative;
}
.tn-media-playlist .tn-playlist-item .playlist-content .card-body .title,
.tn-mediaplayer-widget .tn-media-playlist-up-next .playlist-content .card-body .title { 
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.25em;
    margin-bottom: 1.5em;
}
.playlist-content .card-body .title a:hover {
    text-decoration: none;
}
.tn-mediaplayer-widget .tn-media-playlist-up-next .tn-playlist-item .playlist-content .card-body,
.tn-mediaplayer-widget .tn-media-playlist-up-next .tn-playlist-item .tnt-download {
    display: none;
}
.tn-mediaplayer .tn-media-playlist {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}
@media screen and (max-width: 768px) {
    .tn-mediaplayer .tn-media-playlist {
        display: block;
    }
    .tn-mediaplayer .tn-media-playlist .asset-icon {
        font-size: 0.75em;
    }
    .tn-mediaplayer-widget .tn-media-playlist-up-next {
        display: none;
    }
    .tn-media-playlist .tn-playlist-item .playlist-content { 
        grid-template-areas: "image body body";
        margin: 0;
    }
    .tn-media-playlist .tn-playlist-item .playlist-content figure .image {
        position: inherit;
    }
    .tn-media-playlist .tn-playlist-item .playlist-content .card-body .title {
        position: initial;
        top: auto;
        margin: 0;
        background: none;
        font-size: 1em;
    }
    /* mobile styles for videojs */
    .tnt-video .vjs-control-bar {
        height: 5em;
        font-size: 14px;
        padding-top: 2em;
    }
    .tnt-video.vjs-live .vjs-control-bar { 
        padding-top: 0;
        height: auto;
    }
    
    .tnt-video .vjs-control-bar .vjs-progress-control {
        position: absolute;
        top: 0.75em;
        left: 0;
        width: 100%;
        height: 0.5em;
        padding: 0 0.75em;
    }
    .tnt-video .vjs-control-bar .vjs-time-control {
        flex: auto;
    }
}

/* always increase size of icons */
.tnt-video .vjs-control-bar {
    font-size: 14px;
}
.enhanced-video { 
    margin-bottom: 20px;
    background: #111111;
    color: #ffffff;
    font-size: smaller;
    border-bottom: 2px solid #ccc;
}
.enhanced-video header {
    padding: 20px;
}
.enhanced-video .tn-mediaplayer-description {
    padding: 0 10px 0 20px;
}

.enhanced-video .tn-mediaplayer-no-companion .tn-mediaplayer-description {
    padding-right: 20px;
}

.enhanced-video h1,
.enhanced-video a {
    color: #ffffff;
}
.enhanced-video a.btn.btn-default {
    color: #000000;
}

.tn-mediaplayer-widget {
    background: #efefef;
    color: #444;
    padding-bottom: 10px;
}

.enhanced-video .tn-mediaplayer-widget .tn-media-playlist-up-next:before {
    content: "Up Next";
    color: #ffffff;
}

.tn-mediaplayer-widget .caption-toggle {
    color: #4f4f4f;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    padding: 0px;
}

.tn-mediaplayer-widget .caption-toggle:focus {
    outline: none;
}

.tn-mediaplayer-widget .tn-playlist-item.card {
    margin-bottom: 0;
}

.enhanced-playlist-slideshow .tnt-slider-item .tn-playlist-item .card-body {
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(rgba(0,0,0,0.75), rgba(0,0,0,0));
    box-sizing: border-box;
    padding: 1em;
}
.enhanced-playlist-slideshow .tnt-slider-item .tn-playlist-item .card-body h4 {
    padding: 0;
    margin: 0;
}
.enhanced-playlist-slideshow .tnt-slider .tnt-slider-item {
    overflow: hidden;
}

.enhanced-playlist-slideshow .tnt-slider .tnt-slider-item .now-showing .playlist-content:before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #ffffff;
    background: #000000;
    padding: 4px 8px;
    font-weight: bold;
    content: "Showing";
    z-index: 9999;
}

.tn-mediaplayer .tnt-slider-item .tn-playlist-item .card-body {
    width: 120%;
    position: absolute;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    box-sizing: border-box;
    padding: 1em;
}

.tn-mediaplayer .tnt-slider-item .tn-playlist-item .card-body h4 {
    padding: 0;
    margin: 0;
}

.tn-mediaplayer .tnt-slider-inner {
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-name: bounce;
    animation-timing-function: ease;
    padding-left: 0 !important;
}

@keyframes bounce {
    0%   { transform: translateY(0); }
    30%  { transform: translateX(-40px); }
    50%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}

.tn-mediaplayer .enhanced-playlist-slideshow .tnt-slider .tnt-slider-controls {
    margin: 0;
    position: inherit;
    right: inherit;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    z-index: 200;
}

.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-previous,
.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-next {
    top: inherit;
    color: #999;
    text-decoration: none;
    background-color: transparent;
    border: none;
    z-index: 100;
    cursor: pointer;
    opacity: 1;
    font-size: 50px;
}

.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-previous:hover,
.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-next:hover {
    color: #333;
}

.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-previous {
    left: -60px;
    right: inherit;
}

.tn-mediaplayer .photo-carousel.enhanced-playlist-slideshow .tnt-slider-controls .tnt-slider-next {
    right: -60px;
    left: auto;
}

.tn-mediaplayer .tnt-slider {
    width: 84%;
    margin: 0 auto;
}

.tn-mediaplayer .enhanced-playlist-slideshow .tnt-slider .tnt-slider-item {
    width: 75px !important; /* important overrides the slider's calculated style */
}

.tn-mediaplayer .enhanced-playlist-slideshow .tnt-slider .tnt-slider-item .image-alt img {
    height: 75px;
}

@media only screen and (max-width: 1024px) {
    .tn-mediaplayer .tnt-slider {
        width: 78%;
        margin: 0 auto;
    }
    
    /* iOS BIG/little caption fix */
    .tnt-video-container video::-webkit-media-text-track-display {
        max-width: 80%;
        left: 10%;
        width: 80%;
        font-size: 14px;
        text-align: center;
    }

    .tnt-video-container video-js.is-fullscreen video::-webkit-media-text-track-display {
        font-size: 24px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
    .tnt-video-container video::-webkit-media-text-track-display {
        font-size: 25px !important;
    }
    .tnt-video-container video-js.is-fullscreen video::-webkit-media-text-track-display {
        font-size: 50px !important;
    }
}

@media only screen and (max-width: 425px) {
    .tn-mediaplayer .tnt-slider {
        width: 60%;
    }
}